// src/orderService.js
import axios from 'axios';

// Function to start an order
export const startOrder = async (market, price, quantity,key, secret) => {
  const apiUrl = 'https://api.tokame.network/dcx/makeorder';

  const payload = {
    market,
    price,
    quantity,
    secretdata: 'fufitrade', // Assuming this is a constant
    runtype: 'run',
    key, // Assuming this is a constant
    secret
  };

  try {
    const response = await axios.post(apiUrl, payload);
    return response.data; // Return the response data
  } catch (error) {
    throw new Error(`Error starting order process: ${error.message}`);
  }
};




export const getactivebot = async () => {
  const apiUrl = 'https://api.tokame.network/dcx/activebot';
  try {
    const response = await axios.get(apiUrl,);
    return response.data; // Return the response data
  } catch (error) {
    throw new Error(`Error stopping order process: ${error.message}`);
  }
};

export const stopbot = async (market,key, secret) => {
  const apiUrl = `https://api.tokame.network/dcx/stopbot/${market}`;
  try {
    const response = await axios.get(apiUrl,);
    return response.data; // Return the response data
  } catch (error) {
    throw new Error(`Error stopping order process: ${error.message}`);
  }
};