// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';  // Import Header component
import OrderBot from './components/OrderBot/OrderBot';  // Import OrderBot component
import TradePage from './components/Orderbook/TradePage';  // Import OrderBot component
import OrderBook from './components/Orderbook/OrderBook'; // Import the OrderBook component
// import Pricelist from './components/Orderbook/Pricelist'; // Import the OrderBook component


function Home() {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', margin: '50px' }}>
      <h1>Welcome to the Home Page</h1>
      <p>Click on OrderBot in the header to navigate to the OrderBot page.</p>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Header />  {/* Render the Header component */}
      <Routes>
        <Route path="/" element={<Home />} />  {/* Home page route */}
        <Route path="/orderbot" element={<OrderBot />} />  {/* OrderBot page route */}
        <Route path="/trade" element={<TradePage />} />  {/* OrderBot page route */}
        <Route path="/orderbook" element={<OrderBook />} />


      </Routes>
    </Router>
  );
}

export default App;
