import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { startOrder, getactivebot, stopbot } from './orderService'; // Import the functions
import io from "socket.io-client";

const socket = io("https://socket.tradekia.com");

const OrderBot = () => {
  const [marketList, setMarketList] = useState([]);
  const [market, setMarket] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [secret, setSecret] = useState('');
  const [key, setKey] = useState('');

  const [output, setOutput] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeBots, setActiveBots] = useState([]); // State to store active bots
  const [orders, setOrders] = useState([]); // State to store the list of orders

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        const response = await axios.get('https://api.tokame.network/getallpairdata');
        setMarketList(response.data.result);
      } catch (error) {
        setOutput('Error fetching market data');
      }
    };

    const fetchActiveBots = async () => {
      try {
        const response = await getactivebot();
        if (response.status) {
          setActiveBots(response.result); // Store active bots
        }
      } catch (error) {
        setOutput('Error fetching active bots');
      }
    };

    fetchMarketData();
    fetchActiveBots();
  }, []);

  const handleStart = async () => {
    if (!market || !price || !quantity || !key || !secret) {
      setOutput('All fields are required!');
      return;
    }
    setLoading(true);
    try {
      const data = await startOrder(market, price, quantity, key, secret);
      setOutput(`Order process started: ${JSON.stringify(data)}`);
    } catch (error) {
      setOutput(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle stopping a bot
  const handleStopBot = async (market) => {
    try {
      const response = await stopbot(market);
      if (response.status) {
        setOutput(`Bot stopped for market: ${market}`);
        // Refresh active bots list after stopping
        const updatedBots = activeBots.filter((bot) => bot.market !== market);
        setActiveBots(updatedBots);
      } else {
        setOutput(`Failed to stop bot for market: ${market}`);
      }
    } catch (error) {
      setOutput('Error stopping bot');
    }
  };

  useEffect(() => {
    // Listen for the full list of orders when the component mounts
    socket.on('order-list', (initialOrders) => {
      console.log('Received initial order list:', initialOrders);
      setOrders(initialOrders);
    });

    // Listen for real-time updates to orders
    socket.on('store-order', (orderData) => {
      console.log('Received order data:', orderData);
      setOrders((prevOrders) => {
        const existingOrderIndex = prevOrders.findIndex(order => order.id === orderData.id);
        if (existingOrderIndex !== -1) {
          const updatedOrders = [...prevOrders];
          updatedOrders[existingOrderIndex] = orderData; // Update existing order
          return updatedOrders;
        }
        return [...prevOrders, orderData]; // Add new order
      });
    });

    // Clean up listeners on component unmount
    return () => {
      socket.off('order-list');
      socket.off('store-order');
    };
  }, []);

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', margin: '50px', textAlign: 'center' }}>
      <h1>Place/Update Order</h1>
      <form style={{ maxWidth: '400px', margin: '0 auto', textAlign: 'left' }}>
        {/* Form Inputs */}
        <label htmlFor="market">Market:</label>
        <input
          list="marketList"
          id="marketInput"
          name="market"
          value={market}
          onChange={(e) => setMarket(e.target.value)}
          placeholder="Search or select a market"
          required
          style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
        />
        <datalist id="marketList">
          {marketList.map((marketItem, index) => (
            <option key={index} value={marketItem.symbol} />
          ))}
        </datalist>

        {/* Other Input Fields */}
        <label htmlFor="price">Price:</label>
        <input
          type="number"
          id="price"
          name="price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          step="0.0001"
          required
          style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
        />
        <label htmlFor="quantity">Quantity:</label>
        <input
          type="number"
          id="quantity"
          name="quantity"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          step="0.0001"
          required
          style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
        />
        <label htmlFor="key">Key:</label>
        <input
          type="text"
          id="key"
          name="key"
          value={key}
          onChange={(e) => setKey(e.target.value)}
          required
          style={{ width: '100%', padding: '8px', marginBottom: '20px' }}
        />
        <label htmlFor="secret">Secret Key:</label>
        <input
          type="text"
          id="secret"
          name="secret"
          value={secret}
          onChange={(e) => setSecret(e.target.value)}
          required
          style={{ width: '100%', padding: '8px', marginBottom: '20px' }}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            type="button"
            onClick={handleStart}
            style={{ padding: '10px 20px', backgroundColor: '#28a745', color: '#fff', border: 'none', cursor: 'pointer', fontSize: '16px' }}
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Start'}
          </button>
        </div>
      </form>

      {output && (
        <div className="output" style={{ marginTop: '20px', color: '#333', backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px' }}>
          {output}
        </div>
      )}

      {/* Display Active Bots */}
      {activeBots.length > 0 && (
        <div style={{ marginTop: '30px', textAlign: 'left' }}>
          <h2>Active Bots</h2>
          <ul style={{ listStyleType: 'none', padding: '0' }}>
            {activeBots.map((bot, index) => (
              <li key={index} style={{ backgroundColor: '#f8f9fa', padding: '10px', marginBottom: '5px', borderRadius: '4px' }}>
                <strong>Market:</strong> {bot.market}, <strong>Price:</strong> {bot.price}, <strong>Quantity:</strong> {bot.quantity}
                <button
                  onClick={() => handleStopBot(bot.market)}
                  style={{
                    marginLeft: '10px',
                    padding: '5px 10px',
                    backgroundColor: '#dc3545',
                    color: '#fff',
                    border: 'none',
                    cursor: 'pointer',
                    fontSize: '14px',
                    borderRadius: '3px',
                  }}
                >
                  Stop Bot
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Display Socket Orders */}
      {orders.length > 0 && (
        <div style={{ marginTop: '30px', textAlign: 'left' }}>
          <h2>Socket Order Data</h2>
          <ul style={{ listStyleType: 'none', padding: '0' }}>
            {orders.map((order, index) => (
              <li key={index} style={{ backgroundColor: '#e9ecef', padding: '10px', marginBottom: '5px', borderRadius: '4px' }}>
                <strong>Order ID:</strong> {order.id}, <strong>Market:</strong> {order.market}, <strong>Price Per Unit:</strong> {order.price_per_unit}, <strong>Status:</strong> {order.status}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default OrderBot;
