// src/Header.js
import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header style={{ padding: '20px', backgroundColor: '#007bff', color: '#fff' }}>
      <nav>
        <ul style={{ display: 'flex', listStyleType: 'none', padding: 0 }}>
          <li style={{ marginRight: '20px' }}>
            <Link to="/" style={{ color: '#fff', textDecoration: 'none', fontSize: '18px' }}>
              Home
            </Link>
          </li>
          <li style={{ marginRight: '20px' }}>
            <Link to="/trade" style={{ color: '#fff', textDecoration: 'none', fontSize: '18px' }}>
            Trade
            </Link>
          </li>
          <li>
            <Link to="/orderbot" style={{ color: '#fff', textDecoration: 'none', fontSize: '18px' }}>
              OrderBot
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
