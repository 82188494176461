import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { useParams } from 'react-router-dom';

const socket = io('https://socket.tradekia.com');

const OrderBook = () => {
  const [orderBookData, setOrderBookData] = useState(null);
  const { pair } = useParams();

  useEffect(() => {
    // Subscribe to the order book updates for the selected pair
    socket.emit('join-orderbook', { pair });

    // Listen for order book updates
    socket.on('depth-update', (data) => {
      setOrderBookData(data);
      console.log('Order Book Data:', data);
    });

    // Clean up the listener when the component unmounts or pair changes
    return () => {
      socket.off('depth-update');
    };
  }, []);

  return orderBookData
    
  
};

export default OrderBook;
